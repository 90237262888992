import { inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivateFn,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { lastValueFrom } from 'rxjs';
import { GlobalService } from '../services/global/global.service';
import { LocalStorageService } from '../services/local-storage/local-storage.service';
import { NavigationURLs } from '../utils/application-constants';

export const authGuard: CanActivateFn = async (
  _route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
) => {
  const localStorageService = inject(LocalStorageService);
  const globalService = inject(GlobalService);
  const router = inject(Router);

  // Allow access to login callback and change password pages without auth checks
  if (
    state.url.includes('/login/callback') ||
    state.url === NavigationURLs.CHANGE_PASSWORD
  ) {
    return true;
  }

  try {
    // Check if the user is authenticated
    if (!localStorageService.isAuthenticated()) {
      router.navigate([NavigationURLs.LOGIN]);
      return false;
    }

    // Wait for the API response and ensure localStorage is updated
    const authorizationObject = await lastValueFrom(
      globalService.getRoleModuleMappingData()
    );

    // Authorization checks after localStorage is updated
    if (authorizationObject?.isAuthorized) {
      if (authorizationObject.hasPasswordExpired) {
        router.navigate([NavigationURLs.CHANGE_PASSWORD]);
        return false;
      }
      return true;
    } else {
      router.navigate([NavigationURLs.UNAUTHORIZED_PAGE]);
      return false;
    }
  } catch (error) {
    console.error('Error in authGuard:', error);
    router.navigate([NavigationURLs.UNAUTHORIZED_PAGE]);
    return false;
  }
};
