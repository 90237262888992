import { HttpClient, HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, Subject, throwError } from 'rxjs';
import { catchError, filter, switchMap, take } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { LoaderService } from '../loader/loader.service';
import { LocalStorageService } from '../local-storage/local-storage.service';

@Injectable({
  providedIn: 'root',
})
export class HttpInterceptorService implements HttpInterceptor {
  private isRefreshing = false;
  private refreshTokenSubject = new Subject<string>(); // Emits new token after refresh
  private pendingRequests: Array<{ request: HttpRequest<any>; next: HttpHandler }> = []; // Queue for pending requests

  // private clientId = '0oal50m7t7EK8U9zH5d7';
  // private refreshTokenUrl = 'https://cfbm.okta.com/oauth2/v1/token'; // Dev

  private clientId = environment.oktaConfig.clientId;
  private refreshTokenUrl = environment.oktaConfig.refreshTokenUrl; // Dev

  // private refreshTokenUrl = 'https://dev-04327378.okta.com/oauth2/default/v1/token'; // Local
  // private clientId = '0oak77ummws4KkUwg5d7'; // Local

  constructor(
    private localStorageService: LocalStorageService,
    private router: Router,
    private http: HttpClient,
    private loaderService: LoaderService
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = this.localStorageService.getLoggedInUserData()?.token;

    const clonedRequest = request.url.includes('okta.com') && request.url.includes('v1/token')
      ? request.clone()
      : request.clone({ headers: request.headers.set('Authorization', token ? `Bearer ${token}` : '') });

    return next.handle(clonedRequest).pipe(
      catchError((error: HttpErrorResponse) => {
        if (!request.url.includes('/login') && !request.url.includes('/resetpassword')) {
          if (error.status === 401) {
            const isSsoLogin = localStorage.getItem('isSsoLogin') === 'true';
            if (isSsoLogin && error.error?.error?.toLowerCase().includes('token expired')) {
              return this.handle401Error(clonedRequest, next);
            } else {
              this.router.navigate(['/unauthorized']);
            }
          } else if (error.status === 400) {
            if (request.url.includes('v1/token?') && request.url.includes('grant_type=refresh_token')) {
              const isSsoLogin = localStorage.getItem('isSsoLogin') === 'true';
              if (isSsoLogin && error.error?.error?.toLowerCase().includes('invalid_grant')) {
                this.localStorageService.clear();
                sessionStorage.clear();
                this.router.navigate(['/unauthorized']);
              }
            }
          }
        }
        return throwError(() => error);
      })
    );
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.isRefreshing) {
      // If token refresh is already in progress, queue the request and wait for the new token
      return new Observable((observer) => {
        this.pendingRequests.push({ request, next });
        this.refreshTokenSubject.pipe(
          filter((token) => token !== null), // Wait until we receive the new token
          take(1), // Only take the first emitted token
          switchMap((newToken) => {
            const retryRequest = request.clone({
              headers: request.headers.set('Authorization', `Bearer ${newToken}`)
            });
            return next.handle(retryRequest);
          })
        ).subscribe(observer);
      });
    }

    this.isRefreshing = true;

    const user = this.localStorageService.getLoggedInUserData();
    const refreshToken = user?.refreshToken;
    // const refreshToken = 'OFgEqtnCb6WloO2NzjXg5BzYGqK4zfQ4Zji2yetMQk';

    if (!refreshToken) {
      this.isRefreshing = false;
      this.router.navigate(['/unauthorized']);
      return throwError(() => new Error('No refresh token available'));
    }

    return this.refreshToken(refreshToken).pipe(
      switchMap((newTokens: any) => {
        this.isRefreshing = false;
        const newToken = newTokens.id_token;
        const newRefreshToken = newTokens.refresh_token;
        // const newRefreshToken = 'JOFgEqtnCb6WloO2NzjXg5BzYGqK4zfQ4Zji2yetMQk';

        // Update local storage with new tokens
        const updatedUser = { ...user, token: newToken, refreshToken: newRefreshToken };
        this.localStorageService.setCurrentUser(updatedUser, true, true);

        // Notify all pending requests to retry with new token
        this.refreshTokenSubject.next(newToken);
        this.pendingRequests.forEach(({ request, next }) => {
          const retryRequest = request.clone({
            headers: request.headers.set('Authorization', `Bearer ${newToken}`)
          });
          next.handle(retryRequest).subscribe();
        });

        this.pendingRequests = []; // Clear pending requests
        return next.handle(request.clone({ headers: request.headers.set('Authorization', `Bearer ${newToken}`) }));
      }),
      catchError(() => {
        this.isRefreshing = false;
        this.router.navigate(['/unauthorized']);
        this.loaderService.hideLoader();
        return throwError(() => new Error('Unauthorized'));
      })
    );
  }

  private refreshToken(refreshToken: string): Observable<any> {
    const body = new URLSearchParams();
    body.set('client_id', this.clientId);
    body.set('grant_type', 'refresh_token');
    body.set('refresh_token', refreshToken);

    const url = `${this.refreshTokenUrl}?client_id=${this.clientId}&grant_type=refresh_token&refresh_token=${refreshToken}`;
    return this.http.post<any>(url, {}, { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } });
  }
}