import { inject } from '@angular/core';
import { CanActivateFn, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { LocalStorageService } from '../services/local-storage/local-storage.service';
import { NavigationURLs, SKPTRoles } from '../utils/application-constants';
import { GlobalService } from '../services/global/global.service';

export const routeAccessGuard: CanActivateFn = async (_route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  const localStorageService = inject(LocalStorageService);
  const router = inject(Router);
  const globalService = inject(GlobalService);

  try {
    if (localStorageService.isAuthenticated() && localStorageService.isUserAuthorized()) {
      if (state.url.endsWith('home/projects/project')) {
        if (globalService.isPermitted([SKPTRoles.Platform_Admin, SKPTRoles.Project_Manager, SKPTRoles.Finance_User, SKPTRoles.Sample_Management_User, SKPTRoles.Internal_PD, SKPTRoles.Support_User])) {
          return true;
        } else {
          router.navigate([NavigationURLs.UNAUTHORIZED_PAGE]);
          return false;
        }
      } if (state.url.endsWith('home/clients/client')) {
        if (globalService.isPermitted([SKPTRoles.Platform_Admin, SKPTRoles.Project_Manager, SKPTRoles.Finance_User, SKPTRoles.Sample_Management_User, SKPTRoles.Internal_PD, SKPTRoles.Support_User])) {
          return true;
        } else {
          router.navigate([NavigationURLs.UNAUTHORIZED_PAGE]);
          return false;
        }
      } else {
        return true;
      }
    } else {
      router.navigate([NavigationURLs.UNAUTHORIZED_PAGE]);
      return false;
    }
  } catch (error) {
    console.error(error);
    router.navigate([NavigationURLs.UNAUTHORIZED_PAGE]);
    return false;
  }
};
