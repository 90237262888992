import { HTTP_INTERCEPTORS, provideHttpClient, withFetch, withInterceptorsFromDi } from '@angular/common/http';
import { ApplicationConfig, ErrorHandler, importProvidersFrom } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { provideNoopAnimations } from '@angular/platform-browser/animations';
import { provideRouter } from '@angular/router';
import { OktaAuthModule } from '@okta/okta-angular';
import { OktaAuth } from '@okta/okta-auth-js';
import { environment } from '../environments/environment';
import { routes } from './app.routes';
import { GlobalErrorHandlerService } from './services/error-handling/global-error-handler.service';
import { HttpInterceptorService } from './services/interceptors/http-interceptor.service';

// export function MSALInstanceFactory(): IPublicClientApplication {
//   return new PublicClientApplication({
//     auth: {
//       clientId: environment.msalConfig.auth.clientId,
//       authority: environment.msalConfig.auth.authority,
//       redirectUri: environment.msalConfig.auth.redirectUri,
//       postLogoutRedirectUri: environment.msalConfig.auth.postLogoutRedirectUri
//     },
//     cache: {
//       cacheLocation: BrowserCacheLocation.LocalStorage
//     },
//     system: {
//       allowNativeBroker: false, // Disables WAM Broker
//       loggerOptions: {
//         logLevel: LogLevel.Info,
//         piiLoggingEnabled: false
//       }
//     }
//   });
// }

// export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
//   const protectedResourceMap = new Map<string, Array<string>>();
//   protectedResourceMap.set(environment.apiConfig.uri, environment.apiConfig.scopes);

//   return {
//     interactionType: InteractionType.Popup,
//     protectedResourceMap
//   };
// }

// export function MSALGuardConfigFactory(): MsalGuardConfiguration {
//   return {
//     interactionType: InteractionType.Redirect,
//     authRequest: {
//       scopes: [...environment.apiConfig.scopes]
//     },
//     loginFailedRoute: '/login-failed'
//   };
// }

const oktaAuth = new OktaAuth({
  issuer: environment.oktaConfig.issuer,
  clientId: environment.oktaConfig.clientId,
  redirectUri: environment.oktaConfig.redirectUri,
  scopes: environment.oktaConfig.scopes,
  pkce: environment.oktaConfig.pkce,
});


export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes),
    importProvidersFrom(BrowserModule),
    provideNoopAnimations(),
    provideHttpClient(withInterceptorsFromDi(), withFetch()),
    // {
    //   provide: HTTP_INTERCEPTORS,
    //   useClass: MsalInterceptor,
    //   multi: true
    // },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpInterceptorService,
      multi: true
    },
    // {
    //   provide: MSAL_INSTANCE,
    //   useFactory: MSALInstanceFactory
    // },
    // {
    //   provide: MSAL_GUARD_CONFIG,
    //   useFactory: MSALGuardConfigFactory
    // },
    // {
    //   provide: MSAL_INTERCEPTOR_CONFIG,
    //   useFactory: MSALInterceptorConfigFactory
    // },
    // MsalService,
    // MsalGuard,
    // MsalBroadcastService,
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandlerService
    },
    {
      provide: OktaAuth,
      useValue: oktaAuth
    },
    importProvidersFrom(OktaAuthModule.forRoot({ oktaAuth })),
  ]
};